import { MarkdownRemarkFrontmatter, Maybe } from '../../graphql-types';
import { Theme } from './theme';

export type Category =
  | 'front-end'
  | 'back-end'
  | 'mobile'
  | 'devops'
  | 'design'
  | 'project-management'
  | 'human-resources'
  | 'marketing'
  | 'general'
  | 'default';

export const categoryTheme: { [key in Category]: Theme } = {
  default: 'default',
  'front-end': 'dodger-blue',
  'back-end': 'electric-violet',
  mobile: 'purple-heart',
  devops: 'fuzzy-wuzzy-brown',
  design: 'rose',
  'project-management': 'clementine',
  'human-resources': 'purple',
  marketing: 'gossamer',
  general: 'blue',
};

export const getDescription = (title: string): string => {
  switch (title) {
    case 'Design':
      return 'Tasarım işlerine dair kontrol edilmesi gereken listelerimiz.';

    case 'Front-End':
      return 'Web projeleri için yayın öncesi kontrol edilmesi gereken listelerimiz.';

    case 'Back-End':
      return 'Geliştirilen back-end işleri için kontrol listelerimiz.';

    case 'Mobile':
      return 'Mobil uygulama yayını öncesi kontrol edilmesi gereken listelerimiz.';

    case 'DevOps':
      return 'Ekip organizasyonları öncei bir bakalım.';

    case 'Project Management':
      return 'Yeni bir iş, müşteri sürecinde kontrol edilmesi gereken listelerimiz.';

    case 'Human Resources':
      return 'Ekibimizle ilgili gelişmeler, yeni gelenler ve diğer durumlar için kontrol listelerimiz.';

    case 'Marketing':
      return 'Marka Yönetimi sırasında kontrol etmemiz gereken listelerimiz.';

    case 'General':
      return 'Buraya bakarlar.';

    default:
      return 'A checklist category!';
  }
};

export const getMostUsedTags = (
  nodes?: Array<{ frontmatter?: Maybe<Pick<MarkdownRemarkFrontmatter, 'tags'>> }>,
): string[] => {
  const items: Record<string, number> = {};

  (nodes || []).forEach(node => {
    if (!node.frontmatter) {
      return;
    }

    (node.frontmatter.tags || []).forEach(tag => {
      const tagName = tag || '';
      if (items[tagName]) {
        items[tagName] += 1;
        return;
      }

      items[tagName] = 1;
    });
  });

  return Object.entries(items)
    .sort((i1, i2) => i2[1] - i1[1])
    .map(i => i[0])
    .slice(0, 4);
};
